.studio__container {
    background-image: url("../../../img/Front_Exterior.jpg");
    padding: 0 40px;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    visibility: inherit;
    z-index: 20;

    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.studio__body {
    background-color: black;
    opacity: 0.92;
    color: white;
    height: 995px;
    padding: 60px;
    text-align: justify;
    margin-bottom: 100px;
}

.studio__body .studio__title {
    font-size: 2em;
    font-family: Oswald, sans-serif;
    font-weight: 1000;
    margin-bottom: 50px;
    word-spacing: normal;
    color: rgba(246,246,246,1);
    letter-spacing: 1px;
    word-spacing: 1px;
}

.studio__body p {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
}

.studio__body video {
    width: 100%;
    margin-bottom: 50px;
}

.studio__body .studio__clients {
    line-height: 5px;
    margin: 30px 0;
}

.studio__body .studio__services {
    line-height: 5px;
    margin: 30px 0;
}

.studio__body a {
    text-decoration: none;
    color: #8EC63F;
}

.studio__body a:hover {
    color: white;
    border-bottom: 1px solid white;
}

.studio__body>p>a {
    text-decoration: none;
    color: #8EC63F;
    border-bottom: 1px solid #8EC63F;
}

@media (max-width:992px) {
    .studio__container {
        padding: 0 0;
        background-attachment: fixed;
        overflow: hidden;
    }
    .studio__body .studio__title {
        font-size: 1.5em;
        font-family: Oswald, sans-serif;
        font-weight: 700;
        margin-bottom: 50px;
        word-spacing: normal;
        color: rgba(246,246,246,1);
    }
    .studio__services p {
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 20px;
        width: 100%;
    }
    .studio__clients p{
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 20px;
        width: 100%;
    }
}


@media (min-width:750px) {
    .studio__body {
        width: 700px;
    }
    
}
@media (max-width:500px){
    .studio__body .studio__title {
        font-size: 1em;
        font-family: Oswald, sans-serif;
        font-weight: 700;
        margin-bottom: 50px;
        word-spacing: normal;
        color: rgba(246,246,246,1);
    }
    .studio__body{
        overflow: scroll;
    }
}