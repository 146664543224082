
.learn__main__container{
    background-color: #000000;
    width: 100%;
    height: 100vh;
    padding: 0 10px;

}
.learn_gallery {
    -webkit-column-count: 4;
    -moZ-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    padding-top: 10px;
}

.learn_gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    background-color: white;
}
.learn_gallery .pics img{
    transition: .5s;
    height: 300px;
}
.learn_gallery .pics::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: black;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: 0.5s;
}
.learn_gallery .pics:hover::before{
    opacity: .6;
}

.learn_gallery .pics:hover img{
    /* filter: opacity(.9); */
    z-index: 1;
    transform: scale(1.05);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
}
.learn_gallery .pics .image__overlay{
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
    opacity: 0;
    transition: all 0.4s ease;
    top: 40%;
}
.learn_gallery .pics:hover .image__overlay{
    opacity: 1;
    visibility: visible;
}
.image__overlay h4{
    text-transform: uppercase;
    font-weight: 700;
    transition: all 1s ease;
    font-size: 40px;
    color: white;
    font-family: 'Oswald', sans-serif;
    margin: 5px 0 15px;
    position: relative;
    top: -22px;
}
.learn_gallery .pics:hover .image__overlay h4{
    top: 0;
}
.image__overlay a{
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    font-family: 'Oswald', sans-serif;
    bottom: -22px;
    transition: all 1s ease;
    position: relative;
    text-decoration: none;

    
}
.learn_gallery .pics:hover .image__overlay a{
    bottom: 0;
}
.button-style{
  background-color: #8EC64F;
  border-radius: 30px;
  padding: 10px 30px;
  transition: all .5s ease;
}
.button-style:hover{
    background-color: dimgray;
}


@media(max-width: 991px) {
    .learn_gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
    }
    .learn__main__container{
        height: 100%;
    }
}

@media(max-width: 580px) {
    .learn_gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}


