@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
body {
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}
.header{
  z-index: 9999;
  margin-bottom: 58px;
}
.navbar__container {
  width: 100%;
	margin-right:auto;
	margin-left:auto;
  position: fixed;
  background-color: black;
  text-decoration: none;
  height: 60px;
  padding-top: 3px;
}
.header__middle__logo img {
  max-width: 100%;
  height: 50px;
  width: 100px;
  padding-left: 50px;
}
.header__middle__logo a{
  text-decoration: none;
  color: white;
}

.logo-flex>.brand-name{
  margin-top: 10px;
  font-size: 40px;
  font-weight: 500;
  margin-left: 70px;
  font-family: 'Oswald', sans-serif;
}

.main-nav{
  margin-right: 70px;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo .header__middle__logo img {
  float: left;
  max-height: 120px;
}
.menu-item .is-active {
  color: #8EC64F!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 15px;
  font-weight: 300;
  line-height: 40px;
  position: relative;
  display: block;
  color: #E8E8E8;
  margin: 6px 17px;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
}
.header__middle__logo{
  width: 40%;
  display: inline-block;
  float: left;
  margin-top: -15px;
}
.header__middle__menus {
  width: 60%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 1px dashed lavender;
  position: relative;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: black;
  width: 200px;
  left: -17px;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

.social__media{
  color: white;
  margin-top: 10px;
}
.social__media .wall{
  font-size: 25px;
  margin-top: -10px;
}
.social__media__icon{
  margin-left: -20px;
  margin-top: 5px;
}
.social__media__icon li{
  height: 26px;
  width: 26px;
  background-color: #fff;
    color: #000;
    opacity: 0.5;
  margin-left: 5px;
  border-radius: 50%;
  padding: 1px 0 0 6px;
  transition: all .5s ease-in;
}
.social__media__icon li .facebook__icon{
  margin-left: 2px;
}
.social__media__icon li .youtube__icon{
  margin-left: -2px ;
}
.social__media__icon a:hover li{
  background-color: #8EC64F;
  color: white;
  opacity: 1;
  transition: 50ms;
}

.whatsapp_container{
  background-color: #8EC64F;
  border-radius: 30px;
  padding: 15px 20px;
  display: flex;
  transition: all .5s ease;
}
.whatsapp_container:hover{
  background-color: dimgray;
}

.whatsapp-link{
  color: whitesmoke;
  text-decoration: none;
}
.whatsapp-link:hover{
  color: whitesmoke;
}
.whatsapp-link .whatsapp__icon{
  width: 30px;
  height: 30px;
}
.whatsapp-link p{
  font-weight: 600;
  font-size: 20px;
  word-spacing: 1px;
}

@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
  .social__media .wall{
    display: none;
  }
  .social__media__icon{
    margin: 0 auto;
  }
  .logo-flex>.brand-name{
    margin-top: 23px;
    font-size: 25px;
    font-weight: 500;
    margin-left: 20px;
    font-family: 'Oswald', sans-serif;
    color: white;
  }

}


@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  margin: 0px 20px;
}
.menu-item:hover > a{
  color: #8EC64F !important;
}
.menu-item .sub__menus li:hover a{
  color: #8EC64F !important;
}

.header__middle__logo.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 15px 0 10px 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 8px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}

.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: rgb(199, 137, 137);
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: white;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}