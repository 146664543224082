.service-container {
    margin-top: 60px;
    text-align: center;
    padding-top: 100px;
    padding: 100px 200px;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
}

.service__container__top {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
}

.service__element__top1 {
    height: 300px;
    padding: 130px 0;
    background-image: url('../../../img/1009\ N.\ Ind.jpg');
    width: 720px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}

.service__element__top2 {
    height: 300px;
    padding: 130px 0;
    background-image: url('../../../img/2.ObergeschoßBed_1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
    width: 720px;
}

.service__container__middle {
    display: flex;
    justify-content: space-between;
}

.service__element__middle1 {
    height: 300px;
    padding: 130px 0;
    width: 50vh;
    background-image: url('../../../img/3d Floor Plan/Tanjila\ 3d\ floor\ plan2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}

.service__element__middle2 {
    height: 300px;
    padding: 130px 0;
    background-color: salmon;
    width: 50vh;
    background-image: url('../../../img/V_4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}
.service:hover{
    opacity: .7;
}
.service h1{
    font-size: 40px;
    font-weight: 600;
    color: white;
    background-color: dimgray;
}
.service__element__middle3 {
    height: 300px;
    padding: 130px 0;
    background-color: salmon;
    width: 50vh;
}

.service__container__bottom {
    display: flex;
    justify-content: space-between;
}

.service__element__bottom1 {
    height: 300px;
    padding: 130px 0;
    width: 720PX;
    background-image: url('../../../img/AldersgateRoadTop.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}

.service__element__bottom2 {
    height: 300px;
    padding: 130px 0;
    background-image: url('../../../img/3d Floor Plan/MC\ ground\ floor.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
    width: 720PX;
}

.element {
    padding: 20px;
}

.service__element__top h1 {
    font-size: 40px;
    font-weight: 600;
}

@media(max-width:1900px) {
    .service-container {
        padding: 30px 10px;
    }
}

@media(max-width: 1200px) {
    .service-container {
        padding: 30px 10px;
    }

    .service__element__top h1 {
        font-size: 30px;
        font-weight: 600;
    }

    .service__container__top {
        margin-top: 30px;
        width: 100%;
    }

    .service__container__middle {
        width: 100%;
    }

    .service__container__bottom {
        width: 100%;
    }

    .service__element__bottom2 {
        width: 100%;
    }

    .service__element__bottom1 {
        width: 100%;
    }

    .service__element__middle2 {
        width: 100%;
    }

    .service__element__middle1 {
        width: 100%;
    }

    .service__element__middle3 {
        width: 100%;
    }

    .service__element__top2 {
        width: 100%;
    }

    .service__element__top1 {
        width: 100%;
    }

    .service__container__bottom {
        display: block;
    }

    .service__container__middle {
        display: block;
    }

    .service__container__top {
        display: block;
    }
}