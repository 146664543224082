.films__gallery {
    -webkit-column-count: 3;
    -moZ-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    padding-top: 10px;
    text-align: center;
}

.films__gallery .youtube__video iframe {
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
    height: 35vh;
    position: relative;
}


@media(max-width: 991px) {
    .films__gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
    }
}

@media(max-width: 680px) {
    .films__gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}