@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.career-container{
    background-image: url(/static/media/Front_Exterior.ea180ba1.jpg);
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    visibility: inherit;
    z-index: 20;
    padding: 0 50px;
    text-align: justify;
     background-attachment: fixed;
}
.career-body{
    height: 950px;
    width: 100%;
}
.career-left-part{
    padding:60px;
    background-color: black;
    color: white;
    opacity: 0.92;
    height: 700px;
    width: 689px;
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
}
.career-left-part .career-title{
    font-size: 26px;
    font-family: Oswald, sans-serif;
    font-weight: 500;
    margin: 17.42px 0;
}
.career-left-part h2{
    font-size: 19.5px;
    font-family: Oswald, sans-serif;
    font-weight: 500;
    margin: 16.185px 0;
}
.career-left-part ul li{
    list-style-type: square;
}
.career-left-part a{
    text-decoration: none;
    color: #8EC63F;
}
.career-left-part a:hover{
    color: white;
    border-bottom: 1px solid white;
}
@media (max-width:992px){
    .career-container{
        padding: 0 0;
        background-attachment: fixed;
        overflow: hidden;
    }
    .career-left-part{
        width: 100%;
        overflow: auto;
        width: 100%;
    }
    .career-left-part .career-title{
        font-size: 16px;
        font-family: Oswald, sans-serif;
        font-weight: 500;
        margin: 17.42px 0;
    }
}
.service-container {
    margin-top: 60px;
    text-align: center;
    padding-top: 100px;
    padding: 100px 200px;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
}

.service__container__top {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
}

.service__element__top1 {
    height: 300px;
    padding: 130px 0;
    background-image: url("/static/media/1009 N. Ind.b6f5854c.jpg");
    width: 720px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}

.service__element__top2 {
    height: 300px;
    padding: 130px 0;
    background-image: url(/static/media/2.ObergeschoßBed_1.db279391.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
    width: 720px;
}

.service__container__middle {
    display: flex;
    justify-content: space-between;
}

.service__element__middle1 {
    height: 300px;
    padding: 130px 0;
    width: 50vh;
    background-image: url("/static/media/Tanjila 3d floor plan2.d80c5202.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}

.service__element__middle2 {
    height: 300px;
    padding: 130px 0;
    background-color: salmon;
    width: 50vh;
    background-image: url(/static/media/V_4.165ea048.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}
.service:hover{
    opacity: .7;
}
.service h1{
    font-size: 40px;
    font-weight: 600;
    color: white;
    background-color: dimgray;
}
.service__element__middle3 {
    height: 300px;
    padding: 130px 0;
    background-color: salmon;
    width: 50vh;
}

.service__container__bottom {
    display: flex;
    justify-content: space-between;
}

.service__element__bottom1 {
    height: 300px;
    padding: 130px 0;
    width: 720PX;
    background-image: url(/static/media/AldersgateRoadTop.8e2f9b3a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
}

.service__element__bottom2 {
    height: 300px;
    padding: 130px 0;
    background-image: url("/static/media/MC ground floor.92bfcf60.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: dimgray;
    width: 720PX;
}

.element {
    padding: 20px;
}

.service__element__top h1 {
    font-size: 40px;
    font-weight: 600;
}

@media(max-width:1900px) {
    .service-container {
        padding: 30px 10px;
    }
}

@media(max-width: 1200px) {
    .service-container {
        padding: 30px 10px;
    }

    .service__element__top h1 {
        font-size: 30px;
        font-weight: 600;
    }

    .service__container__top {
        margin-top: 30px;
        width: 100%;
    }

    .service__container__middle {
        width: 100%;
    }

    .service__container__bottom {
        width: 100%;
    }

    .service__element__bottom2 {
        width: 100%;
    }

    .service__element__bottom1 {
        width: 100%;
    }

    .service__element__middle2 {
        width: 100%;
    }

    .service__element__middle1 {
        width: 100%;
    }

    .service__element__middle3 {
        width: 100%;
    }

    .service__element__top2 {
        width: 100%;
    }

    .service__element__top1 {
        width: 100%;
    }

    .service__container__bottom {
        display: block;
    }

    .service__container__middle {
        display: block;
    }

    .service__container__top {
        display: block;
    }
}
.studio__container {
    background-image: url(/static/media/Front_Exterior.ea180ba1.jpg);
    padding: 0 40px;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    visibility: inherit;
    z-index: 20;

    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.studio__body {
    background-color: black;
    opacity: 0.92;
    color: white;
    height: 995px;
    padding: 60px;
    text-align: justify;
    margin-bottom: 100px;
}

.studio__body .studio__title {
    font-size: 2em;
    font-family: Oswald, sans-serif;
    font-weight: 1000;
    margin-bottom: 50px;
    word-spacing: normal;
    color: rgba(246,246,246,1);
    letter-spacing: 1px;
    word-spacing: 1px;
}

.studio__body p {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
}

.studio__body video {
    width: 100%;
    margin-bottom: 50px;
}

.studio__body .studio__clients {
    line-height: 5px;
    margin: 30px 0;
}

.studio__body .studio__services {
    line-height: 5px;
    margin: 30px 0;
}

.studio__body a {
    text-decoration: none;
    color: #8EC63F;
}

.studio__body a:hover {
    color: white;
    border-bottom: 1px solid white;
}

.studio__body>p>a {
    text-decoration: none;
    color: #8EC63F;
    border-bottom: 1px solid #8EC63F;
}

@media (max-width:992px) {
    .studio__container {
        padding: 0 0;
        background-attachment: fixed;
        overflow: hidden;
    }
    .studio__body .studio__title {
        font-size: 1.5em;
        font-family: Oswald, sans-serif;
        font-weight: 700;
        margin-bottom: 50px;
        word-spacing: normal;
        color: rgba(246,246,246,1);
    }
    .studio__services p {
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 20px;
        width: 100%;
    }
    .studio__clients p{
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        line-height: 20px;
        width: 100%;
    }
}


@media (min-width:750px) {
    .studio__body {
        width: 700px;
    }
    
}
@media (max-width:500px){
    .studio__body .studio__title {
        font-size: 1em;
        font-family: Oswald, sans-serif;
        font-weight: 700;
        margin-bottom: 50px;
        word-spacing: normal;
        color: rgba(246,246,246,1);
    }
    .studio__body{
        overflow: scroll;
    }
}

.contact__container{
    background-image: url(/static/media/Front_Exterior.ea180ba1.jpg);
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: inherit;
    z-index: 20;
    padding: 0 50px;
    text-align: justify;
    background-attachment: fixed;
}
.contact__body{
    height: 950px;
}
.contact__left__part{
    padding:80px 60px;
    background-color: black;
    color: #E1E1E1;
    opacity: 0.92;
    height: 700px;
    width: 666px;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
}
.contact__top__part a{
    text-decoration: none;
    color: #8EC63F;
}
.contact__top__part a:hover{
    color: white;
    border-bottom: 1px solid white;
}
.contact__bottom__part{
    font-size: 18px;
}
.contact__bottom__part a{
    text-decoration: none;
    color: #8EC63F;
}
.contact__bottom__part a:hover{
    color: white;
    border-bottom: 1px solid white;
}


@media (max-width:992px){
    .contact__container{
        padding: 0 0;

    }
}
@media (max-width:650px){
    .contact__left__part{
        overflow-y: scroll;
    }
}



.all__main__container{
    background-color: #000000;
    width: 100%;
    height: 100%;
    padding: 0 10px;
}
.gallery {
    -webkit-column-count: 3;
    -moZ-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    padding-top: 10px;
}

.gallery .pics {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
}
.gallery .pics img{
    transition: .5s;
}
.gallery .pics::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: black;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: 0.5s;
}
.gallery .pics:hover::before{
    opacity: .6;
}

.gallery .pics:hover img{
    /* filter: opacity(.9); */
    z-index: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
}
.gallery .pics .image__overlay{
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
    opacity: 0;
    transition: all 0.4s ease;
    top: 40%;
}
.gallery .pics:hover .image__overlay{
    opacity: 1;
    visibility: visible;
}
/* .image__overlay h4{
    text-transform: uppercase;
    font-weight: 700;
    transition: all 1s ease;
    font-size: 30px;
    color: white;
    font-family: 'Oswald', sans-serif;
    margin: 5px 0 15px;
    position: relative;
    top: -22px;
}
.gallery .pics:hover .image__overlay h4{
    top: 0;
}
.image__overlay p{
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    font-family: 'Oswald', sans-serif;
    bottom: -22px;
    transition: all 1s ease;
    position: relative;
}
.gallery .pics:hover .image__overlay p{
    bottom: 0;
} */



@media(max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
    }
}

@media(max-width: 580px) {
    .gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        column-width: 100%;
    }
}

/* model */

.model{
    width: 100%;
    height: 95vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition: opacity .4s ease, visibility .4s ease, -webkit-transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    overflow: hidden;
    z-index: 999;
    margin-top: 60px;
}
.model.open{
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);

}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin:  0 auto;

}
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}



.all__main__container{
    background-color: #000000;
    width: 100%;
    height: 100%;
    padding: 0 10px;
}
.gallery {
    -webkit-column-count: 3;
    -moZ-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    padding-top: 10px;
}

.gallery .pics {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
}
.gallery .pics img{
    transition: .5s;
}
.gallery .pics::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: black;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: 0.5s;
}
.gallery .pics:hover::before{
    opacity: .6;
}

.gallery .pics:hover img{
    /* filter: opacity(.9); */
    z-index: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
}
.gallery .pics .image__overlay{
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
    opacity: 0;
    transition: all 0.4s ease;
    top: 40%;
}
.gallery .pics:hover .image__overlay{
    opacity: 1;
    visibility: visible;
}
/* .image__overlay h4{
    text-transform: uppercase;
    font-weight: 700;
    transition: all 1s ease;
    font-size: 30px;
    color: white;
    font-family: 'Oswald', sans-serif;
    margin: 5px 0 15px;
    position: relative;
    top: -22px;
}
.gallery .pics:hover .image__overlay h4{
    top: 0;
}
.image__overlay p{
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    font-family: 'Oswald', sans-serif;
    bottom: -22px;
    transition: all 1s ease;
    position: relative;
}
.gallery .pics:hover .image__overlay p{
    bottom: 0;
} */



@media(max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
    }
}

@media(max-width: 580px) {
    .gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        column-width: 100%;
    }
}

/* model */

.model{
    width: 100%;
    height: 95vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition: opacity .4s ease, visibility .4s ease, -webkit-transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    overflow: hidden;
    z-index: 999;
    margin-top: 60px;
}
.model.open{
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);

}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin:  0 auto;

}
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}


.all__main__container{
    background-color: #000000;
    width: 100%;
    height: 100%;
}
.gallery {
    -webkit-column-count: 3;
    -moZ-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    padding-top: 10px;
}

.gallery .pics {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
}
.gallery .pics img{
    transition: .5s;
}
.gallery .pics::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: black;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: 0.5s;
}
.gallery .pics:hover::before{
    opacity: .6;
}

.gallery .pics:hover img{
    /* filter: opacity(.9); */
    z-index: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
}
.gallery .pics .image__overlay{
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
    opacity: 0;
    transition: all 0.4s ease;
    top: 40%;
}
.gallery .pics:hover .image__overlay{
    opacity: 1;
    visibility: visible;
}
/* .image__overlay h4{
    text-transform: uppercase;
    font-weight: 700;
    transition: all 1s ease;
    font-size: 30px;
    color: white;
    font-family: 'Oswald', sans-serif;
    margin: 5px 0 15px;
    position: relative;
    top: -22px;
}
.gallery .pics:hover .image__overlay h4{
    top: 0;
}
.image__overlay p{
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    font-family: 'Oswald', sans-serif;
    bottom: -22px;
    transition: all 1s ease;
    position: relative;
}
.gallery .pics:hover .image__overlay p{
    bottom: 0;
} */



@media(max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
    }
}

@media(max-width: 580px) {
    .gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        column-width: 100%;
    }
}

/* model */

.model{
    width: 100%;
    height: 95vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition: opacity .4s ease, visibility .4s ease, -webkit-transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    overflow: hidden;
    z-index: 999;
    margin-top: 60px;
}
.model.open{
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);

}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin:  0 auto;

}
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}
.films__gallery {
    -webkit-column-count: 3;
    -moZ-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    padding-top: 10px;
    text-align: center;
}

.films__gallery .youtube__video iframe {
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
    height: 35vh;
    position: relative;
}


@media(max-width: 991px) {
    .films__gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        column-width: 50%;
    }
}

@media(max-width: 680px) {
    .films__gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        column-width: 100%;
    }
}
.cliper-middle{
    text-align: center;
}

.learn__main__container{
    background-color: #000000;
    width: 100%;
    height: 100vh;
    padding: 0 10px;

}
.learn_gallery {
    -webkit-column-count: 4;
    -moZ-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    column-width: 25%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    padding-top: 10px;
}

.learn_gallery .pics {
    transition: all 350ms ease;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    background-color: white;
}
.learn_gallery .pics img{
    transition: .5s;
    height: 300px;
}
.learn_gallery .pics::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: black;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: 0.5s;
}
.learn_gallery .pics:hover::before{
    opacity: .6;
}

.learn_gallery .pics:hover img{
    /* filter: opacity(.9); */
    z-index: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
}
.learn_gallery .pics .image__overlay{
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
    opacity: 0;
    transition: all 0.4s ease;
    top: 40%;
}
.learn_gallery .pics:hover .image__overlay{
    opacity: 1;
    visibility: visible;
}
.image__overlay h4{
    text-transform: uppercase;
    font-weight: 700;
    transition: all 1s ease;
    font-size: 40px;
    color: white;
    font-family: 'Oswald', sans-serif;
    margin: 5px 0 15px;
    position: relative;
    top: -22px;
}
.learn_gallery .pics:hover .image__overlay h4{
    top: 0;
}
.image__overlay a{
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    font-family: 'Oswald', sans-serif;
    bottom: -22px;
    transition: all 1s ease;
    position: relative;
    text-decoration: none;

    
}
.learn_gallery .pics:hover .image__overlay a{
    bottom: 0;
}
.button-style{
  background-color: #8EC64F;
  border-radius: 30px;
  padding: 10px 30px;
  transition: all .5s ease;
}
.button-style:hover{
    background-color: dimgray;
}


@media(max-width: 991px) {
    .learn_gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
    }
    .learn__main__container{
        height: 100%;
    }
}

@media(max-width: 580px) {
    .learn_gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        column-width: 100%;
    }
}



body {
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}
.header{
  z-index: 9999;
  margin-bottom: 58px;
}
.navbar__container {
  width: 100%;
	margin-right:auto;
	margin-left:auto;
  position: fixed;
  background-color: black;
  text-decoration: none;
  height: 60px;
  padding-top: 3px;
}
.header__middle__logo img {
  max-width: 100%;
  height: 50px;
  width: 100px;
  padding-left: 50px;
}
.header__middle__logo a{
  text-decoration: none;
  color: white;
}

.logo-flex>.brand-name{
  margin-top: 10px;
  font-size: 40px;
  font-weight: 500;
  margin-left: 70px;
  font-family: 'Oswald', sans-serif;
}

.main-nav{
  margin-right: 70px;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo .header__middle__logo img {
  float: left;
  max-height: 120px;
}
.menu-item .is-active {
  color: #8EC64F!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 15px;
  font-weight: 300;
  line-height: 40px;
  position: relative;
  display: block;
  color: #E8E8E8;
  margin: 6px 17px;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
}
.header__middle__logo{
  width: 40%;
  display: inline-block;
  float: left;
  margin-top: -15px;
}
.header__middle__menus {
  width: 60%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 1px dashed lavender;
  position: relative;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: black;
  width: 200px;
  left: -17px;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

.social__media{
  color: white;
  margin-top: 10px;
}
.social__media .wall{
  font-size: 25px;
  margin-top: -10px;
}
.social__media__icon{
  margin-left: -20px;
  margin-top: 5px;
}
.social__media__icon li{
  height: 26px;
  width: 26px;
  background-color: #fff;
    color: #000;
    opacity: 0.5;
  margin-left: 5px;
  border-radius: 50%;
  padding: 1px 0 0 6px;
  transition: all .5s ease-in;
}
.social__media__icon li .facebook__icon{
  margin-left: 2px;
}
.social__media__icon li .youtube__icon{
  margin-left: -2px ;
}
.social__media__icon a:hover li{
  background-color: #8EC64F;
  color: white;
  opacity: 1;
  transition: 50ms;
}

.whatsapp_container{
  background-color: #8EC64F;
  border-radius: 30px;
  padding: 15px 20px;
  display: flex;
  transition: all .5s ease;
}
.whatsapp_container:hover{
  background-color: dimgray;
}

.whatsapp-link{
  color: whitesmoke;
  text-decoration: none;
}
.whatsapp-link:hover{
  color: whitesmoke;
}
.whatsapp-link .whatsapp__icon{
  width: 30px;
  height: 30px;
}
.whatsapp-link p{
  font-weight: 600;
  font-size: 20px;
  word-spacing: 1px;
}

@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
  .social__media .wall{
    display: none;
  }
  .social__media__icon{
    margin: 0 auto;
  }
  .logo-flex>.brand-name{
    margin-top: 23px;
    font-size: 25px;
    font-weight: 500;
    margin-left: 20px;
    font-family: 'Oswald', sans-serif;
    color: white;
  }

}


@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  margin: 0px 20px;
}
.menu-item:hover > a{
  color: #8EC64F !important;
}
.menu-item .sub__menus li:hover a{
  color: #8EC64F !important;
}

.header__middle__logo.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 15px 0 10px 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 8px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}

.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: rgb(199, 137, 137);
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: white;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}
