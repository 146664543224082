.all__main__container{
    background-color: #000000;
    width: 100%;
    height: 100%;
}
.gallery {
    -webkit-column-count: 3;
    -moZ-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    margin-top: 60px;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
    padding-top: 10px;
}

.gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    /* new update */
    position: relative;
    overflow: hidden;
}
.gallery .pics img{
    transition: .5s;
}
.gallery .pics::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: black;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: 0.5s;
}
.gallery .pics:hover::before{
    opacity: .6;
}

.gallery .pics:hover img{
    /* filter: opacity(.9); */
    z-index: 1;
    transform: scale(1.05);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
}
.gallery .pics .image__overlay{
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
    opacity: 0;
    transition: all 0.4s ease;
    top: 40%;
}
.gallery .pics:hover .image__overlay{
    opacity: 1;
    visibility: visible;
}
/* .image__overlay h4{
    text-transform: uppercase;
    font-weight: 700;
    transition: all 1s ease;
    font-size: 30px;
    color: white;
    font-family: 'Oswald', sans-serif;
    margin: 5px 0 15px;
    position: relative;
    top: -22px;
}
.gallery .pics:hover .image__overlay h4{
    top: 0;
}
.image__overlay p{
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    font-family: 'Oswald', sans-serif;
    bottom: -22px;
    transition: all 1s ease;
    position: relative;
}
.gallery .pics:hover .image__overlay p{
    bottom: 0;
} */



@media(max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moZ-column-count: 2;
        column-count: 2;
    }
}

@media(max-width: 580px) {
    .gallery {
        -webkit-column-count: 1;
        -moZ-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}

/* model */

.model{
    width: 100%;
    height: 95vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
    margin-top: 60px;
}
.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);

}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin:  0 auto;

}
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}