.career-container{
    background-image: url("../../../img/Front_Exterior.jpg");
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    visibility: inherit;
    z-index: 20;
    padding: 0 50px;
    text-align: justify;
     background-attachment: fixed;
}
.career-body{
    height: 950px;
    width: 100%;
}
.career-left-part{
    padding:60px;
    background-color: black;
    color: white;
    opacity: 0.92;
    height: 700px;
    width: 689px;
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
}
.career-left-part .career-title{
    font-size: 26px;
    font-family: Oswald, sans-serif;
    font-weight: 500;
    margin: 17.42px 0;
}
.career-left-part h2{
    font-size: 19.5px;
    font-family: Oswald, sans-serif;
    font-weight: 500;
    margin: 16.185px 0;
}
.career-left-part ul li{
    list-style-type: square;
}
.career-left-part a{
    text-decoration: none;
    color: #8EC63F;
}
.career-left-part a:hover{
    color: white;
    border-bottom: 1px solid white;
}
@media (max-width:992px){
    .career-container{
        padding: 0 0;
        background-attachment: fixed;
        overflow: hidden;
    }
    .career-left-part{
        width: 100%;
        overflow: auto;
        width: 100%;
    }
    .career-left-part .career-title{
        font-size: 16px;
        font-family: Oswald, sans-serif;
        font-weight: 500;
        margin: 17.42px 0;
    }
}