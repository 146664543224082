
.contact__container{
    background-image: url("../../img/Front_Exterior.jpg");
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: inherit;
    z-index: 20;
    padding: 0 50px;
    text-align: justify;
    background-attachment: fixed;
}
.contact__body{
    height: 950px;
}
.contact__left__part{
    padding:80px 60px;
    background-color: black;
    color: #E1E1E1;
    opacity: 0.92;
    height: 700px;
    width: 666px;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
}
.contact__top__part a{
    text-decoration: none;
    color: #8EC63F;
}
.contact__top__part a:hover{
    color: white;
    border-bottom: 1px solid white;
}
.contact__bottom__part{
    font-size: 18px;
}
.contact__bottom__part a{
    text-decoration: none;
    color: #8EC63F;
}
.contact__bottom__part a:hover{
    color: white;
    border-bottom: 1px solid white;
}


@media (max-width:992px){
    .contact__container{
        padding: 0 0;

    }
}
@media (max-width:650px){
    .contact__left__part{
        overflow-y: scroll;
    }
}
